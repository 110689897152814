<template>
    <div class="earn">
        <Top/>
        <Menu/>
        <div class="earn_content">
            <div class="earn_content01">
                <div class="earn_content01_01">
                    <div :class="verifySignIn(1)">
                        <div class="earn_content01_0111">DAY 1</div>
                        <div>+500 GC</div>
                    </div>
                    <div :class="verifySignIn(2)">
                        <div class="earn_content01_0111">DAY 2</div>
                        <div>+500 GC</div>
                    </div>
                    <div :class="verifySignIn(3)">
                        <div class="earn_content01_0111">DAY 3</div>
                        <div>+1000 GC</div>
                    </div>
                    <div :class="verifySignIn(4)">
                        <div class="earn_content01_0111">DAY 4</div>
                        <div>+1000 GC</div>
                    </div>
                    <div :class="verifySignIn(5)">
                        <div class="earn_content01_0111">DAY 5</div>
                        <div>+2000 GC</div>
                    </div>
                    <div :class="verifySignIn(6)">
                        <div class="earn_content01_0111">DAY 6</div>
                        <div>+3000 GC</div>
                    </div>
                    <div :class="verifySignIn(7)">
                        <div class="earn_content01_0111">DAY 7</div>
                        <div>+5000 GC</div>
                    </div>
                </div>
                <div class="earn_content01_02">
                    <div :style="isSignIn ? 'background: #5c5c5c; cursor: not-allowed;' : ''" @click="onSignInIntegral()">{{isSignIn ? 'Claimed' : 'Claim'}}</div>
                </div>
            </div>

            <div class="earn_content02">

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/htg_tp.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Telegram channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="true" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="false" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/htg_tp.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Telegram channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="false" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="true" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/twitter_logo.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Twitter channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="true" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="false" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/twitter_logo.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Twitter channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="false" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="true" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/f_tp.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Facebook channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="true" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="false" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

                <div class="earn_content02_01">
                    <div class="earn_content02_011">
                        <div class="earn_content02_0111">
                            <img src="../../assets/images/f_tp.jpg"/>
                        </div>
                        <div class="earn_content02_0112">
                            <div class="earn_content02_01121">Join Facebook channel</div>
                            <div class="earn_content02_01122">+100 GC</div>
                        </div>
                    </div>
                    <div class="earn_content02_012">
                        <div v-show="false" class="start02_012" @click="onStartEarn()">Start</div>
                        <div v-show="true" class="claim02_012" @click="onClaimInvite()">Claim</div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import Top from "../../components/framing/Top";
    import Menu from "../../components/framing/Menu";
    import request from "../../tools/request";

    export default {
        name: "Index",
        components: {Top, Menu},
        data() {
            return {
                userId: 0,
                isSignIn: false,
                signInNum: 0,
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }

            this.getVerifyUserLogin();
            this.getVerifyBindingAndSignIn();
        },
        destroyed() {
        },
        methods: {
            async getVerifyUserLogin() {
                if (sessionStorage.getItem("userId")) {
                    this.userId = sessionStorage.getItem("userId");

                    await request.getVerifyUserLogin(sessionStorage.getItem("userId")).then((res) => {
                        if (!res.data) {
                            this.$router.push("/login");
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                } else {
                    this.$router.push("/login");
                }
            },
            async getVerifyBindingAndSignIn() {
                if (this.userId) {
                    await request.getVerifyBindingAndSignIn(this.userId).then((res) => {
                        this.isSignIn = res.data.isSignIn;
                        this.signInNum = res.data.signInNum;
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            verifySignIn(num) {
                if (this.isSignIn) {
                    if (num > this.signInNum) {
                        return 'earn_content01_011';
                    } else {
                        return 'earn_content01_011 already_claim';
                    }
                } else {
                    if (num <= this.signInNum) {
                        return 'earn_content01_011 already_claim';
                    } else if (num === this.signInNum + 1) {
                        return 'earn_content01_011 on_claim';
                    } else {
                        return 'earn_content01_011';
                    }
                }
            },
            async onSignInIntegral() {
                if (this.isSignIn) {
                    this.$message.warning("Signed in");
                } else {
                    if (this.userId) {
                        await request.userSignIn(this.userId).then((res) => {
                            this.$message.success("Sign in successful");

                            this.getVerifyBindingAndSignIn();
                        }).catch((err) => {
                            this.$message.error(err.message);
                        })
                    }
                }
            },
            onStartEarn() {
                this.$message.warning("Coming soon!");
                return;
            },
            onClaimInvite() {
                this.$message.warning("Coming soon!");
                return;
            }
        }
    }
</script>

<style lang="less">
    .earn {
        background-color: #131313;
        position: absolute;
        width: 100%;
        height: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        .earn_content {
            position: absolute;
            top: 100px;
            bottom: 100px;
            width: 100%;
            .earn_content01 {
                height: 280px;
                max-height: 280px;
                border-bottom: darkgray solid 0.5px;
                .earn_content01_01 {
                    margin: 0 auto;
                    max-width: 650px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    justify-content: center;
                    align-items: center;
                    .on_claim {
                        cursor: pointer;
                        background: #027d93 !important;
                    }
                    .already_claim {
                        background: #393939 !important;
                        cursor: not-allowed !important;
                    }
                    .earn_content01_011 {
                        background: black;
                        border-radius: 10px;
                        border: darkgray solid 1px;
                        color: white;
                        font-size: 15px;
                        width: 80px;
                        height: 60px;
                        margin: 25px 15px 5px 15px;
                        .earn_content01_0111 {
                            height: 30px;
                            line-height: 30px;
                            font-size: 16px;
                            text-align: center;
                        }
                        div {
                            text-align: center;
                        }
                    }
                }
                .earn_content01_02 {
                    width: 100%;
                    height: 80px;

                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    justify-content: space-around;
                    align-items: center;
                    div {
                        width: 280px;
                        height: 35px;
                        background: #027d93;
                        border-radius: 10px;
                        border: darkgray solid 0.5px;
                        color: white;
                        font-size: 15px;
                        font-weight: 600;
                        text-align: center;
                        line-height: 35px;
                        cursor: pointer;
                        margin-top: 10px;
                    }
                }
            }
            .earn_content02::-webkit-scrollbar {
                width: 0;
            }
            .earn_content02 {
                overflow: hidden;
                overflow-y: auto;

                width: 100%;
                height: calc(100% - 280px);
                .earn_content02_01 {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: space-around;

                    height: 85px;
                    .earn_content02_011 {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-start;
                        align-items: center;
                        .earn_content02_0111 {
                            margin: 10px;
                            img {
                                width: 45px;
                                border: 1px solid #000;
                                border-radius: 50%;
                            }
                        }
                        .earn_content02_0112 {
                            color: white;
                            .earn_content02_01121 {
                                height: 25px;
                                line-height: 30px;
                            }
                            .earn_content02_01122 {
                                height: 35px;
                                line-height: 35px;
                            }
                        }
                    }
                    .earn_content02_012 {
                        .start02_012 {
                            width: 60px;
                            height: 30px;
                            background: white;
                            border-radius: 10px;
                            border: darkgray solid 0.5px;
                            color: #027d93;
                            font-size: 14px;
                            line-height: 30px;
                            text-align: center;
                            cursor: pointer;
                        }
                        .claim02_012 {
                            width: 60px;
                            height: 30px;
                            background: #027d93;
                            border-radius: 10px;
                            border: darkgray solid 0.5px;
                            color: white;
                            font-size: 14px;
                            line-height: 30px;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
    }
</style>
